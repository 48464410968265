<template>
  <div>
      <!-- <v-btn @click="getRank()">SHOW List</v-btn> -->
      <!-- <v-btn @click="getRankummary()">summary</v-btn> -->
      <!-- <v-btn @click="importExampleTicket()">Import Example</v-btn> -->
      <!-- <hr> -->
      <v-container class="rank_wrap wrap">
        <h1 class="mb-5">邀請排行榜</h1>
  <v-row>
    <v-col
      cols="12"
      sm="4"
    >
      <v-select
        v-model="activity"
        :items="invitList"
        outlined
        label="依活動搜尋"
        item-text="title"
        item-value="id"
        hide-details
        :menu-props="{maxWidth:376,minWidth:300}"
        @input="choseAct"
      ></v-select>
    </v-col>
    <v-col
        cols="12"
        sm="4"
      >
        <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="0"
        transition="scale-transition"
        offset-y
        min-width="300"
        max-width="376"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            outlined
            label="時間搜尋"
            readonly
            v-bind="attrs"
            class="mb-5"
            hide-details
            v-on="on"
            style="max-width:400px;"
            :append-icon="dateRangeText?'mdi-close':''"
            @click:append="clearSearch"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          locale="zh-cn"
          no-title
          range
          full-width
          style="width:100%"
          id="dateMenu"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
        <v-data-table
        :headers="headers"
        :items="ranks"
        :loading="isloading"
        class="elevation-1 mb-5"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-avatar class="ma-1" size="44">
              <img
                :src="item.url"
                :alt="item.name"
                style="object-fit:cover;"
              >
            </v-avatar>
            {{item.name}}
          </template>
          <template v-slot:[`item.rank`]="{ item }">
            <div>
              {{ item.rank }}
            </div>
          </template>
        </v-data-table>
      </v-container>
  </div>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL;
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: "Ticket",
  components: { moment },
  data() {
    return {
      headers: [
          { text: '邀請人', value: 'name', width:500,},
          { text: '排名', value: 'rank', width: 100, align:'center'},
          { text: '邀請人數', value: 'cnt', width: 150, align:'center'},
      ],
      ranks: [],
      dates: [],
      actDates: [],
      menu2: false,
      activity: null,
      invitList: [],
    }
  },
  computed:{
        ...mapGetters(['isloading','invitations']),
        dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
  filters: {
    moment: function (date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  mounted () {
    this.getRank()
    this.setInvits()
  },
  watch:{
    menu2(val){
      if(!val){
        this.getRank()
      }
    }
  },
  methods: {
    moment(date) {
            let a = moment(date).tz("Asia/Taipei");
            return a;
    },
    setInvits(){
      let inv = JSON.parse(localStorage.getItem('invitations'))
      if (inv) this.invitList = inv
      else this.invitList = this.invitations
    },
    choseAct(id){
      let item = this.invitList.find((el2,idx2,arr)=> {
          return idx2 == arr.findIndex(el=>el.id==id)
      })
      console.log(item);
      let sd = new Date(item.start_at)
      let ed = new Date(item.end_at)
      let ch_sd = new Date(sd.setHours(sd.getHours() + 8)).toISOString()
      let ch_ed = new Date(ed.setHours(ed.getHours() + 8)).toISOString()
      this.actDates = [ ch_sd, ch_ed ]
      this.dates = [ ch_sd.slice(0,10), ch_ed.slice(0,10) ]
      console.log('this.dates',this.dates);
      this.getRank('act')
    },
    async getRank(act) {
        try {
            let time = ''
            if(this.dates.length!=0){
              let startDate = null
              let endDate = null 
              if(!act){
                startDate = new Date(this.dates[0])
                endDate = new Date(this.dates[1])
                if(endDate<startDate){
                  let newStart = endDate
                  let newEnd = startDate
                  startDate = newStart
                  endDate = newEnd
                  this.dates = [this.dates[1],this.dates[0]]
                }
                startDate = startDate.toISOString()
                endDate = new Date(endDate.setSeconds(endDate.getSeconds() + 86399)).toISOString()
              }else{
                startDate = new Date(this.actDates[0]).toISOString()
                endDate = new Date(this.actDates[1]).toISOString()
              }
              time = `?start_at=${startDate}&end_at=${endDate}`
              console.log('d',time);
            }
            
            let resp = await axios.get(`${host_url}/invitations/relations/${time}`);
            console.log(resp.data);
            let rankList = resp.data
            let cnt = null
            let rankNo = 1
            rankList.forEach((el)=>{
              // console.log('cnt != el.cnt',cnt != el.cnt,'cnt',cnt,'el.cnt',el.cnt,'rankNo',rankNo);
              if(cnt != el.cnt){
                cnt = el.cnt
                el.rank = rankNo++
              }else{
                el.rank = rankNo-1
              }
              
            })
            this.ranks = resp.data
            
        } catch (error) {
            console.log(error);
            throw new Error('getRank Fail')
        }
    },
    clearSearch(){
      this.activity = null
      this.dates = []
      this.getRank()
    },
  },
};
</script>
<style lang="scss" scoped>
@media(min-width: 1441px){
  .rank_wrap{
      max-width: 1200px;
  }
}

</style>
<style>
.v-date-picker-table.v-date-picker-table--date.theme--light{
  margin-bottom: 16px;
}
.v-date-picker-table.v-date-picker-table--date.theme--light table button{
  padding: 20px !important;
}
</style>